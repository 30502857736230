import axios from 'axios'
axios.defaults.withCredentials = true;


export default class UserService {

  getUsersAll() {
    return axios.get('https://advantex.uz/users/getUsers').then(response => response.data, (error) => {
      console.log(error);
    });
  }

  editUser(user) {
    var data = JSON.stringify([
      {
        "id": user.id,
        "email": user.email,
        "identity": user.email,
        "password": user.password,
        "first_name": user.first_name,
        "last_name": user.last_name,
        "company": user.company,
        "phone": user.phone
      }
    ]);

    var config = {
      method: 'post',
      url: 'https://advantex.uz/auth/edit_user_api',
      headers: {
        'Content-Type': 'application/json',
      },
      data: data
    };

    return axios(config)
  }


  insertUser(user) {
    var data = JSON.stringify([
      {
        "email": user.email,
        "identity": user.email,
        "password": user.password,
        "first_name": user.first_name,
        "last_name": user.last_name,
        "company": user.company,
        "phone": user.phone
      }
    ]);

    var config = {
      method: 'post',
      url: 'https://advantex.uz/auth/create_user_api',
      headers: {
        'Content-Type': 'application/json',
      },
      data: data
    };

    return axios(config)
  }

  deleteUser() {

  }

}  
