<template>
    <div class="p-grid">
		<div class="p-col-12">
			<div class="card">
				<Toast/>
				<DataTable :value="users" :paginator="true" class="p-datatable-customers" :rows="10" dataKey="company_id" :rowHover="true" 
                        :filters="filters1" :loading="loading1">
					<template #header>
						<div class="table-header">
									List of users
									<Button label="New" icon="pi pi-plus" class="p-button-success p-input-icon-left p-mr-2 p-mb*2 m-auto-left" @click="openNewUser" />
									<span class="p-input-icon-left">
										<i class="pi pi-search" />
										<InputText v-model="filters1['global']" placeholder="Global Search" />
									</span>	
						</div>
					</template>
					
					<template #loading>
						Loading users data. Please wait.
					</template>
					<Column field="username" header="Username" :sortable="true" sortField="username">
						<template #body="slotProps" index="username">
							{{slotProps.data.username}}
						</template>
					</Column>
					<Column header="Email" :sortable="true" sortField="email" filterField="email">
						<template #body="slotProps">
							<span v-tooltip="slotProps.data.email" style="margin-left: .5em; vertical-align: middle" class="image-text">{{slotProps.data.email}}</span>
						</template>
					</Column>
					<Column field="first_name" header="First Name" :sortable="true" sortField="first_name">
						<template #body="slotProps">
							<span>{{slotProps.data.first_name}}</span>
						</template>
					</Column>
					<Column field="last_name" header="Last Name" :sortable="true" sortField="last_name">
						<template #body="slotProps">
							<span>{{slotProps.data.last_name}}</span>
						</template>
					</Column>
					<Column field="company" header="Company" :sortable="true" sortField="company">
						<template #body="slotProps">
							<span :class="'customer-badge '">{{slotProps.data.company}}</span>
						</template>
					</Column>
					<Column field="phone" header="Phone" :sortable="true" sortField="phone">
						<template #body="slotProps">
							<span :class="'customer-badge' ">{{slotProps.data.phone}}</span>
						</template>
					</Column>
					<Column headerStyle="width: 8rem; text-align: center" bodyStyle="text-align: center; overflow: visible">
						<template #body="slotProps">
							<Button type="button" icon="pi pi-pencil" class="p-button-rounded p-button-success" @click="openModal(slotProps.data)"></Button>
						</template>
					</Column>
					<Column headerStyle="width: 8rem; text-align: center" bodyStyle="text-align: center; overflow: visible">
						<template #body="slotProps">
							<Button type="button" icon="pi pi-trash" class="p-button-rounded p-button-warning" @click="openDeleteModal(slotProps.data)"></Button>
						</template>
					</Column>
				</DataTable>

                <Dialog header="Dialog" v-model:visible="displayEditModal" :style="{width: '30vw'}" :modal="true">
					<div class="p-col-12">
						<div class="card">
							<h5>User information</h5>
							<div class="p-fluid p-formgrid p-grid">
								<div class="p-field p-col-12 p-md-6">
									<label for="username">Username</label>
									<InputText id="username" type="text" v-model="selectedUser.username"/>
								</div>
								<div class="p-field p-col-12 p-md-6">
									<label for="email">Email</label>
									<InputText id="email" type="email" v-model="selectedUser.email"/>
								</div>
								<div class="p-field p-col-12 p-md-6">
									<label for="firstname">First name</label>
									<InputText id="firstname" type="text" v-model="selectedUser.first_name"/>
								</div>
								<div class="p-field p-col-12 p-md-6">
									<label for="lastname">Last name</label>
									<InputText id="lastname" type="text" v-model="selectedUser.last_name"/>
								</div>
								<div class="p-field p-col-12 p-md-6">
									<label for="company">Company</label>
									<InputText id="company" type="text" v-model="selectedUser.company"/>
								</div>
								<div class="p-field p-col-12 p-md-6">
									<label for="phone">Phone</label>
									<InputText id="phone" type="tel" v-model="selectedUser.phone"></InputText>
								</div>
								<div class="p-field p-col-12 p-md-6">
									<label for="password">Password</label>
									<InputText id="password" type="password" v-model="selectedUser.password"></InputText>
								</div>
								<div class="p-field p-col-12 p-md-6">
									<label for="confirmPassword">Confirm password</label>
									<InputText id="confirmPassword" type="password" v-model="selectedUser.confirm_password" ></InputText>
								</div>
							</div>
						</div>
					</div>
					<template #footer>
						<Button label="Save" @click="saveUser" icon="pi pi-check" class="p-button-text"/>
						<Button label="Dismiss" @click="closeModal" icon="pi pi-times" class="p-button-text"/>
					</template>
				</Dialog>

				<Dialog header="Dialog" v-model:visible="displayDeleteModal" :style="{width: '30vw'}" :modal="true">
					<p>
						Are you sure to delete?
					</p>
					<template #footer>
						<Button label="Yes" @click="deleteCompany" icon="pi pi-check" class="p-button-text"/>
						<Button label="No" @click="closeDeleteModal" icon="pi pi-times" class="p-button-text"/>
					</template>
				</Dialog>
				
			</div>
		</div>
	</div>
</template>

<script>

    import UserService from "../service/UserService";
	
	export default {
		data() {
			return {
                users:[],
				selectedUser: {name:'', email:'', address:'', representative:'', phone:''},
				filters1: {},
				filters2: {},
				loading1: true,
				expandedRows: [],
                displayEditModal: false,
				displayNewModal: false,
				displayDeleteModal: false
			}
		},
		userService: null,
        created() {
            this.userService = new UserService();
		},
		mounted() {
			this.onMounted();
		},
		methods: {
			onMounted(){
				this.userService.getUsersAll().then(data => {
					if (data.isLogged){
						this.users = data.fetch_data
						this.loading1 = false;
					} else {
						this.$router.push('Login')
					}
				});
			
			},

            openModal: function(rowId){
				this.selectedUser = rowId;
				this.displayEditModal = true;
			},
            closeModal(){
                this.displayEditModal = false;
				this.selectedUser = {};
			},
			openDeleteModal: function(rowId){
				this.selectedUser = rowId;
				this.displayDeleteModal = true;
			},
			closeDeleteModal(){
				this.displayDeleteModal = false;
				this.selectedUser = {}
			},
			deleteUser(){
				this.userService.deleteUser(this.selectedUser);
				this.closeDeleteModal()
			},
			openNewUser(){
				this.selectedUser = {}
				this.displayEditModal = true;
			},
			saveUser() {
				if (this.selectedUser.password == this.selectedUser.confirm_password) {
					
					if (this.selectedUser.id) {
						this.userService.editUser(this.selectedUser)
						.then(response => {
							console.log(response)
							return response
						})
						.then(response => {					
							this.closeModal();
							return response
						})
						.then(response => {
							this.$toast.add({severity:'success', summary: 'Successful', detail: response.data.fetch_data, life: 3000});
							return response
						})
						.then(response => {
							this.onMounted()
							return response
						}) 
						.catch(error => {
							console.log(error)
						})
					} else {
						this.userService.insertUser(this.selectedUser)
						.then(response => {
							console.log(response)
							return response
						})
						.then(response => {					
							this.closeModal();
							return response
						})
						.then(response => {
							this.$toast.add({severity:'success', summary: 'Successful', detail: response.data.fetch_data, life: 3000});
							return response
						})
						.then(response => {
							this.onMounted()
							return response
						}) 
						.catch(error => {
							console.log(error)
						})
					}
				}
				else {
					this.$toast.add({severity:'danger', summary: 'Unsuccessfull', detail: "Password not confirmed", life: 3000});
				}
			}
		}
	}
</script>

<style scoped lang="scss">
::v-deep(.p-progressbar) {
	height: .5rem;
	background-color: #D8DADC;

	.p-progressbar-value {
		background-color: #607D8B;
	}
}

.p-datatable .p-column-filter {
	display: none;
}

.table-header {
	display: flex;
	justify-content: space-between;
}

::v-deep(.p-datatable.p-datatable-customers) {
	.p-datatable-header {
		padding: 1rem;
		text-align: left;
		font-size: 1.5rem;
	}

	.p-paginator {
		padding: 1rem;
	}

	.p-datatable-thead > tr > th {
		text-align: left;
	}

	.p-datatable-tbody > tr > td {
		cursor: auto;
        overflow:hidden;
	}

	.p-dropdown-label:not(.p-placeholder) {
		text-transform: uppercase;
	}
}

/* Responsive */
.p-datatable-customers .p-datatable-tbody > tr > td .p-column-title {
	display: none;
}

.customer-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-qualified {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-unqualified {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-negotiation {
		background: #FEEDAF;
		color: #8A5340;
	}

	&.status-new {
		background: #B3E5FC;
		color: #23547B;
	}

	&.status-renewal {
		background: #ECCFFF;
		color: #694382;
	}

	&.status-proposal {
		background: #FFD8B2;
		color: #805B36;
	}
}

.p-progressbar-value.ui-widget-header {
	background: #607d8b;
}

@media (max-width: 640px) {
	.p-progressbar {
		margin-top: .5rem;
	}
}

.product-image {
	width: 100px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23)
}

.orders-subtable {
	padding: 1rem;
}

.product-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-instock {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-outofstock {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-lowstock {
		background: #FEEDAF;
		color: #8A5340;
	}
}

.order-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.order-delivered {
		background: #C8E6C9;
		color: #256029;
	}

	&.order-cancelled {
		background: #FFCDD2;
		color: #C63737;
	}

	&.order-pending {
		background: #FEEDAF;
		color: #8A5340;
	}

	&.order-returned {
		background: #ECCFFF;
		color: #694382;
	}
}

@media screen and (max-width: 960px) {
	::v-deep(.p-datatable) {
		&.p-datatable-customers {
			.p-datatable-thead > tr > th,
			.p-datatable-tfoot > tr > td {
				display: none !important;
			}

			.p-datatable-tbody > tr {
				> td {
					text-align: left;
					display: block;
					border: 0 none !important;
					width: 100% !important;
					float: left;
					clear: left;
					border: 0 none;

					.p-column-title {
						padding: .4rem;
						min-width: 30%;
						display: inline-block;
						margin: -.4rem 1rem -.4rem -.4rem;
						font-weight: bold;
					}

					.p-progressbar {
						margin-top: .5rem;
					}
				}
			}
		}
	}
}
</style>
